import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Tokenomics = () => {
  return (
    <>
      <div className="content-box tokenomics">
        <div className="header">
          <div className="title">Tokenomics</div>

          <div className="text">
            $BEAR made 589,000,000 tokens and blackholed the issuer address. He
            went on and gifted 90% to the community.{" "}
            <span>Because&nbsp;he&nbsp;is&nbsp;greedy,</span> he kept 10% for
            himself.
          </div>
        </div>

        <div className="content">
          <div className="image-box">
            <div className="image">
              <img src="images/traderbear.jpeg" alt="" />
            </div>
          </div>
          <div className="stats">
            <div className="stat">
              <div className="label">Total Supply</div>
              <div className="value">589,000,000 $BEAR</div>
            </div>
            <div className="stat address">
              <div className="label">Issuer Address</div>
              <motion.div
                className="value"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                layout="position"
              >
                <Link
                  className="link"
                  to="https://bithomp.com/explorer/rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW
                </Link>
              </motion.div>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xrpl.services/?issuer=rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW&currency=BEAR&limit=589000000"
                target="_blank"
                rel="noopener noreferrer"
              >
                Set $BEAR Trustline
              </Link>
            </motion.button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
