import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { motion, useAnimate, useInView } from "framer-motion";
import Marquee from "./Marquee";

const image1Url = "images/socials/twitter.svg";
const image2Url = "images/bearable-head.png";
const image3Url = "images/socials/discord.jpeg";

const Header = ({ setImagesLoaded, isLoaded }) => {
  const titleRef = useRef();
  const textRef = useRef();
  const buttonRef = useRef();
  const socialsRef = useRef();
  const imageRef = useRef();
  const [scope, animate] = useAnimate();

  const [image1Loaded, setImage1Loaded] = useState(false);
  const [image2Loaded, setImage2Loaded] = useState(false);

  const beforeAnimation = { opacity: 0, scale: 0 };
  const animation = { opacity: 1, scale: 1 };
  const animationDration = {
    opacity: { duration: 0.3 },
    scale: { duration: 0.3 },
  };

  const headerAnimation = async () => {
    const sequence = [
      [titleRef.current, animation, animationDration],
      [
        imageRef.current,
        {
          y: 0,
          opacity: 1,
        },
        {
          opacity: { duration: 0.2 },
          y: {
            ease: "easeInOut",
            duration: 0.6,
          },
        },
      ],
      [textRef.current, animation, animationDration],
      [buttonRef.current, animation, animationDration],
      [socialsRef.current, animation, animationDration],
    ];
    await animate(sequence);
  };

  useEffect(() => {
    if (isLoaded) headerAnimation();
  }, [isLoaded]);

  useEffect(() => {
    const imageLoadPromise1 = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = image1Url;
    }).then(() => {
      setImage1Loaded(true);
    });

    const imageLoadPromise2 = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = image2Url;
    }).then(() => {
      setImage2Loaded(true);
    });
  }, []);

  useEffect(() => {
    console.log("test");
    if (image1Loaded && image2Loaded) {
      console.log("setImagesLoaded");
      setImagesLoaded(true);
    }
  }, [image1Loaded, image2Loaded]);

  return (
    <>
      {isLoaded && (
        <>
          <div className="header">
            <motion.div className="title" ref={titleRef} initial={beforeAnimation} layout="position">
              <h1>BEAR</h1>
              <h2>Powered by XRPL</h2>
            </motion.div>

            <div className="content">
              <div className="header-infos">
                <div className="infos">
                  <motion.div className="text" initial={beforeAnimation} ref={textRef} layout="position">
                    <span>$BEAR</span>&nbsp;is&nbsp;a greedy gambler with anger issues&nbsp;on&nbsp;the&nbsp;XRPL
                  </motion.div>

                  <motion.button
                    ref={buttonRef}
                    initial={{ ...beforeAnimation, rotate: "5deg" }}
                    animate={animation}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                    className="button"
                    layout="position"
                  >
                    <Link
                      className="link"
                      to="https://xpmarket.com/dex/BEAR-rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW/XRP"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get $BEAR
                    </Link>
                  </motion.button>

                  <motion.div className="socials" ref={socialsRef} initial={beforeAnimation} animate={animation}>
                    <Link
                      className="social twitter"
                      to="https://twitter.com/BearXRPL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <motion.div
                        className="icon"
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                        layout="position"
                      >
                        <img src={image1Url} alt="" />
                      </motion.div>
                      {/* <div className="twitter-handle">@bearXRPL</div> */}
                    </Link>
                    <Link
                      className="social discord"
                      to="https://discord.gg/atGTNuzqH6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <motion.div
                        className="icon"
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                        layout="position"
                      >
                        <img src={image3Url} alt="" />
                      </motion.div>
                    </Link>
                  </motion.div>
                </div>
              </div>
              <div className="header-image">
                <motion.div
                  className="image"
                  ref={imageRef}
                  initial={{ y: "calc(100% - 50px)", opacity: 0 }}
                  // animate={{ x: 100 }}
                >
                  <img src={image2Url} alt="" />
                </motion.div>
              </div>
            </div>
          </div>
          <Marquee marqueeContent={<div>$BEAR</div>} />
        </>
      )}{" "}
    </>
  );
};

export default Header;
